import { executeOnSingleOrMultiple, } from "tsparticles-engine";
import { BubbleBase } from "./BubbleBase";
export class BubbleDiv extends BubbleBase {
    constructor() {
        super();
        this.selectors = [];
    }
    get ids() {
        return executeOnSingleOrMultiple(this.selectors, (t) => t.replace("#", ""));
    }
    set ids(value) {
        this.selectors = executeOnSingleOrMultiple(value, (t) => `#${t}`);
    }
    load(data) {
        super.load(data);
        if (!data) {
            return;
        }
        if (data.ids !== undefined) {
            this.ids = data.ids;
        }
        if (data.selectors !== undefined) {
            this.selectors = data.selectors;
        }
    }
}
